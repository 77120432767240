import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"
import "../styles/pages-global.less"
import "../styles/layout.less"
import ContactComponent from "../components/contactComponent"

const Offer = () => (
  <>
    <SEO title="Oferta" />
    <Layout>
      <section className="page-container">
        <div className="page-content container">
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle subpage-subtitle">
                Systemy Master key
              </h2>
              <p>
                Master key czyli tzw. system klucza generalnego - umożliwia
                dostęp do dowolnej liczby drzwi za pomocą jednego klucza przy
                jednoczesnym wprowadzeniu pełnej kontroli dostępu do
                poszczególnych pomieszczeń, przy czym w dalszym ciągu możliwe
                jest stosowanie kluczy indywidualnych. Systemy Master Key mogą
                być prostymi układami dostępu, które będą obejmować małe liczby
                drzwi, np. tylko 3 sztuki i w tym wypadku będą stanowić prostą
                strukturę organizacyjną. Duże systemy (stosowane w hotelach i
                obiektach przemysłowych) obejmują nawet kilka tysięcy
                punktów/drzwi.
              </p>
              <h2 className="section-subtitle subpage-subtitle">
                Zastosowania systemów Master key
              </h2>
              <ul className="subpage-list">
                <li>mieszkania</li>
                <li>domy jednorodzinne</li>
                <li>biura i biurowce</li>
                <li>centra handlowe</li>
                <li>fabryki i obiekty przemysłowe</li>
                <li>hotele</li>
                <li>obiekty użyteczności publicznej</li>
              </ul>
              <p>
                Dzięki temu, że systemy Master Key umożliwiają dostarczenie
                kluczy o różnym dostępie do poszczególnych pomieszczeń i
                obiektów liczba potencjalnych zastosowań dla nich jest
                praktycznie nieograniczona.
              </p>
              <p>
                Prosty system klucza spełnia z reguły funkcję systemu zamknięć
                na jeden klucz (tzw. system jednego klucza - zamki ujednolicone)
                i pozwala zastąpić pęk kluczy jednym kluczem, który otwiera
                wszystkie drzwi. System klucza generalnego, tzw. Master Key
                (inaczej układ centralnego otwierania) pozwala na otwarcie i
                zamknięcie dowolnej liczby drzwi jednym kluczem oraz
                wprowadzenie pełnej kontroli dostępu do pomieszczeń przy pomocy
                kluczy grupowych i indywidualnych. Montaż takiego systemu polega
                na zastosowaniu, bądź wymianie wkładek bębenkowych we wszystkich
                pomieszczeniach, które nas interesują.
              </p>
            </div>
            <div className="col-lg-6">
              <div className="photo-container shoes">
                <iframe
                  title="system key video"
                  className="ytplayer"
                  src="https://www.youtube.com/embed/UYtUllsZldU?controls=0"
                  frameborder="0"
                  allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"

                ></iframe>
              </div>
              <ContactComponent />
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <h2 className="section-subtitle subpage-subtitle">
                Zalety systemów Master key
              </h2>
              <ul className="subpage-list">
                <li>wygoda w administrowaniu obszarem</li>
                <li>
                  bezpieczeństwo (np: możliwość otwierania w sytuacji zagrożenia
                  (np. pożar) jednym kluczem generalnym wszystkich pomieszczeń
                  (szybka ewakuacja ludzi itp.)
                </li>
                <li>
                  jeden klucz umożliwiający dostęp tylko do wybranych
                  pomieszczeń i obiektów (ochrona przed osobami
                  nieupoważnionymi)
                </li>
                <li>
                  system może być oparty na atestowanych wkładkach
                  antywłamaniowych, posiadających najwyższe certyfikaty klasy
                  C/6EN
                </li>
              </ul>
            </div>
            <div className="col-lg-6"></div>
          </div>
        </div>
      </section>
    </Layout>
  </>
)

export default Offer
